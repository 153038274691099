import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import "../styles/Home.css";


const handleIconClick = (url) => {
    window.open(url, '_blank');
  };
function Home() {
    return(

        

        <div className="home"  style={{background: "#dedede"}}> 
            <div className="about"> 
            <h2>Hi, My Name Is Shawn</h2>
            <div className="prompt">
                <p>A software developer with a passion for learning and creating.</p>
                <LinkedInIcon onClick={() => handleIconClick("https://www.linkedin.com/in/shawnmian/")} style={{ cursor: 'pointer' }} />
                <EmailIcon onClick={() => window.location.href = "mailto:shawn@shawnmian.com"} style={{ cursor: 'pointer' }} />
                <GitHubIcon onClick={() => handleIconClick("https://github.com/shawnmian")} style={{ cursor: 'pointer' }} />

                </div>
            </div>
            <div className="skills">
                <h1>Skills</h1>
                <ol className = "list">
                    <li className = "item">
                        <h2> Front-End</h2>
                        <span>ReactJS, HTML, CSS, BootStrap, MaterialUI, Yarn</span>
                    </li>
                    <li className = "item">
                        <h2> Back-End</h2>
                        <span>NodeJS, MySQL, MongoDB, AWS S3</span>
                    </li>
                    <li className = "item">
                        <h2> Languages</h2>
                        <span>Python, C++, C, Javascript, Java, Kotlin, SQL</span>
                    </li>
                </ol> 
            </div>
        </div>
    );
}

export default Home;
