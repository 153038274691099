import salary from '../assets/salary.jpg';

export const ProjectList = [
    
    {
        name: "Negotiation: (In Progress)",
        image: salary
    }



]