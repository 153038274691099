import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import "../styles/Footer.css"

const handleIconClick = (url) => {
    window.open(url, '_blank');
  };


function Footer(){
    return (
        <div className = "footer">
            <div className = "SocialMedia">
                <LinkedInIcon sx={{fontsize: 100}} color="white" onClick={() => handleIconClick("https://www.linkedin.com/in/shawnmian/")} style={{ cursor: 'pointer' }}/>
                <p>Email me at: shawn@shawnmian.com</p>
            </div>
            <p> &copy;2024 shawnmian.com</p>
        </div>
    )
}

export default Footer;