import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import WorkIcon from '@mui/icons-material/Work';

function Experience() {
    return(
        <div className='Experience' style={{background: "#dedede"}}>
            {" "}
            <VerticalTimeline lineColor='#3e497a'>
            <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: '#3e497a', color: '#dedede' }}
    contentArrowStyle={{ borderRight: '7px solid  #3e497a' }}
    date= ""
    iconStyle={{ background: '#3e497a', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">General Dynamics: Mission Systems</h3>
    <h4 className="vertical-timeline-element-subtitle">Software Engineering Intern | May 2023 - August 2023</h4>
    <p>
    ● Implemented software defined radio using C and C++ into proprietary system
    <br></br>
    ● Developed decoder and demodulation programs for ADS-B, GOES, and 802.11 waveforms
    <br></br>
    ● Deployed code to FPGA boards to test efficiency capabilities
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: '#3e497a', color: '#dedede' }}
    contentArrowStyle={{ borderRight: '7px solid  #3e497a' }}
    date=""
    iconStyle={{ background: '#3e497a', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">University Technology Office (ASU)</h3>
    <h4 className="vertical-timeline-element-subtitle">Technical Data Analyst | May 2020 - August 2023"</h4>
    <p>
    ● Used python and natural language processing techniques to analyze student discussion posts
    <br></br>
    ● Worked as a data analyst using Tableu and SQL to analyze data and create presentations
    <br></br>
    ● Analyzed student Canvas and Cogbooks data to improve learning material and retention
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: '#3e497a', color: '#dedede' }}
    contentArrowStyle={{ borderRight: '7px solid  #3e497a' }}
    date=""
    iconStyle={{ background: '#3e497a', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Summit Automation</h3>
    <h4 className="vertical-timeline-element-subtitle">Computer Engineering Intern | May 2022 - August 2023</h4>
    <p>
    ● Developed a custom cable testing apparatus and control module PCB using Cadence.
    <br></br>
    ● Used Atmel Studio to develop and implement embedded code for the control board.
    <br></br>
    ● Reduced testing time of cables by several hours
    </p>

  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: '#3e497a', color: '#dedede' }}
    contentArrowStyle={{ borderRight: '7px solid  #3e497a' }}
    date=""
    iconStyle={{ background: '#3e497a', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Luminosity Lab (ASU)</h3>
    <h4 className="vertical-timeline-element-subtitle">Computer Engineer | Feb 2020 - December 2022</h4>
    <p>
    ● Designed and prototyped electrical circuitry for lunar probe
    <br></br>
    ● Researched and developed a alternative modality form for a lunar rover
    </p>

  </VerticalTimelineElement>


  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    contentStyle={{ background: '#3e497a', color: '#dedede' }}
    contentArrowStyle={{ borderRight: '7px solid  #3e497a' }}
    date=""
    iconStyle={{ background: '#3e497a', color: '#fff' }}
    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Translational Genomics Research Institue (TGen)</h3>
    <h4 className="vertical-timeline-element-subtitle"> Bioinformatics engineer | May 2019 - August 2019</h4>
    <p>
    ● Optimized cell identification pipeline for RNA sequencing using machine learning tools
    <br></br>
    ● Analyzed Single cell RNA sequencing data to graph and identify cell types.
    <br></br>
    ● Automated cell identification pipeline reducing time from 8 hours – 1hour
    </p>

  </VerticalTimelineElement>
  

  
  

  
  
            </VerticalTimeline>
        </div>
    )
}

export default Experience;